define("discourse/plugins/discourse-ai/discourse/components/admin-report-emotion", ["exports", "@ember/component", "@ember-decorators/component", "discourse-common/lib/get-url", "@ember/template-factory"], function (_exports, _component, _component2, _getUrl, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cell title">
    {{#if this.model.icon}}
      {{d-icon this.model.icon}}
    {{/if}}
    <a href="{{this.filterURL}}{{this.model.type}}">{{this.model.title}}</a>
  </div>
  
  <div class="cell value today-count">{{number this.model.todayCount}}</div>
  
  <div
    class="cell value yesterday-count {{this.model.yesterdayTrend}}"
    title={{this.model.yesterdayCountTitle}}
  >
    {{number this.model.yesterdayCount}}
    {{d-icon this.model.yesterdayTrendIcon}}
  </div>
  
  <div
    class="cell value sevendays-count {{this.model.sevenDaysTrend}}"
    title={{this.model.sevenDaysCountTitle}}
  >
    {{number this.model.lastSevenDaysCount}}
    {{d-icon this.model.sevenDaysTrendIcon}}
  </div>
  
  <div
    class="cell value thirty-days-count {{this.model.thirtyDaysTrend}}"
    title={{this.model.thirtyDaysCountTitle}}
  >
    {{number this.model.lastThirtyDaysCount}}
  
    {{#if this.model.canDisplayTrendIcon}}
      {{d-icon this.model.thirtyDaysTrendIcon}}
    {{/if}}
  </div>
  */
  {
    "id": "aONEgyi7",
    "block": "[[[10,0],[14,0,\"cell title\"],[12],[1,\"\\n\"],[41,[30,0,[\"model\",\"icon\"]],[[[1,\"    \"],[1,[28,[35,1],[[30,0,[\"model\",\"icon\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,3],[15,6,[29,[[30,0,[\"filterURL\"]],[30,0,[\"model\",\"type\"]]]]],[12],[1,[30,0,[\"model\",\"title\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"cell value today-count\"],[12],[1,[28,[35,2],[[30,0,[\"model\",\"todayCount\"]]],null]],[13],[1,\"\\n\\n\"],[10,0],[15,0,[29,[\"cell value yesterday-count \",[30,0,[\"model\",\"yesterdayTrend\"]]]]],[15,\"title\",[30,0,[\"model\",\"yesterdayCountTitle\"]]],[12],[1,\"\\n  \"],[1,[28,[35,2],[[30,0,[\"model\",\"yesterdayCount\"]]],null]],[1,\"\\n  \"],[1,[28,[35,1],[[30,0,[\"model\",\"yesterdayTrendIcon\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[15,0,[29,[\"cell value sevendays-count \",[30,0,[\"model\",\"sevenDaysTrend\"]]]]],[15,\"title\",[30,0,[\"model\",\"sevenDaysCountTitle\"]]],[12],[1,\"\\n  \"],[1,[28,[35,2],[[30,0,[\"model\",\"lastSevenDaysCount\"]]],null]],[1,\"\\n  \"],[1,[28,[35,1],[[30,0,[\"model\",\"sevenDaysTrendIcon\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[15,0,[29,[\"cell value thirty-days-count \",[30,0,[\"model\",\"thirtyDaysTrend\"]]]]],[15,\"title\",[30,0,[\"model\",\"thirtyDaysCountTitle\"]]],[12],[1,\"\\n  \"],[1,[28,[35,2],[[30,0,[\"model\",\"lastThirtyDaysCount\"]]],null]],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"canDisplayTrendIcon\"]],[[[1,\"    \"],[1,[28,[35,1],[[30,0,[\"model\",\"thirtyDaysTrendIcon\"]]],null]],[1,\"\\n\"]],[]],null],[13]],[],false,[\"if\",\"d-icon\",\"number\"]]",
    "moduleName": "discourse/plugins/discourse-ai/discourse/components/admin-report-emotion.hbs",
    "isStrictMode": false
  });
  const AdminReportEmotion = dt7948.c(class AdminReportEmotion extends _component.default {
    get filterURL() {
      let aMonthAgo = moment().subtract(1, "month").format("YYYY-MM-DD");
      return (0, _getUrl.default)(`/filter?q=activity-after%3A${aMonthAgo}%20order%3A`);
    }
  }, [(0, _component2.attributeBindings)("model.description:title"), (0, _component2.classNames)("admin-report-counters")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminReportEmotion);
});